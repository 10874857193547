import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";


import VueLocalForage from 'vue-localforage'
Vue.use(VueLocalForage)

import VueAnalytics from 'vue-analytics';

Vue.use(VueAnalytics, {
    id: 'UA-272033375',
    router
})

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.$cookies.config('27d','','',true)

Vue.config.productionTip = false;


import Embed from 'v-video-embed'

// global register
Vue.use(Embed);
import VueSweetalert2 from 'vue-sweetalert2';

// Import Bootstrap an BootstrapVue CSS files (order is important)


// Make BootstrapVue available throughout your project
// Optionally install the BootstrapVue icon components plugin

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';


Vue.use(VueSweetalert2);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

import axios from 'axios'
import localforage from 'localforage';
var multiForage = require("multiforage")(localforage);
import Cookies from "js-cookie";
import store from '..';

const actions = {

    async deleteModeratorChat({ commit }, form) {

        multiForage.getItems(['moderator_username', 'moderator_access'], function (err, items) {
            form.moderator_username = items.moderator_username
            form.moderator_access = items.moderator_access


            console.log(form)
            //todo remember to change api address
            return axios.post('https://apiss.ardkon.com/ModeratorDeleteChat?basics=axb', form).then(() => {

                //dispatch('getDonationTotalModerator', { debate_id: form.debate_id, moderator_id: form.moderator_id  })
                commit('REMOVE_CHAT', form.chat_id)
            })
        })

    }, async deleteQuestionModerator({ commit }, form) {

        multiForage.getItems(['moderator_username', 'moderator_access'], function (err, items) {
            form.moderator_username = items.moderator_username
            form.moderator_access = items.moderator_access


            console.log(form)
            //todo remember to change api address
            return axios.post('https://apiss.ardkon.com/ModeratorDeleteQuestions?basics=axb', form).then(() => {

                //dispatch('getDonationTotalModerator', { debate_id: form.debate_id, moderator_id: form.moderator_id  })
                commit('REMOVE_QUESTION', form.question_id)
            })
        })

    },
    moderatorLogin({ commit }, form) {
        console.log(form)

        console.log(form)
        return axios.post('https://apiss.ardkon.com/ModeratorDebateLogin?basics=axb', form)
            .then(response => {
                console.log(response.data.data.moderator_info.Authorization)

                if (response.data.data.moderator_info.Authorization === 'Success') {
                    store.dispatch('ledger_auth/setModeratorDebate')
                    localforage.setItem("moderator_username", form.username);
                    localforage.setItem("moderator_access", response.data.data.moderator_info.access_moderator);
                    commit('SET_USER_MODERATOR_LOGIN')
                }


            }).catch(error => {
                console.log(error)
            });

    },
    async setRateModerator({ state, }, form) {

        multiForage.getItems(['access_token', 'email_address'], function (err, items) {
            form.access_token = items.access_token
            form.email_address = items.email_address
            form.debate_id = state.active_debate.debate_id
            form.moderator_id = state.active_debate.moderator.moderator_id
            form.moderator_name = state.active_debate.moderator.moderator_name
            //todo remember to change api address
            return axios.post('https://apiss.ardkon.com/RateModerator?basics=axb', form).then((res) => {

                //dispatch('getDonationTotalModerator', { debate_id: form.debate_id, moderator_id: form.moderator_id  })
                console.log(res)
            })
        })

    }, async setDonationModerator({ state, dispatch }, form) {

        multiForage.getItems(['access_token', 'email_address'], function (err, items) {
            form.access_token = items.access_token
            form.email_address = items.email_address
            form.debate_id = state.active_debate.debate_id
            form.moderator_id = state.active_debate.moderator.moderator_id
            form.moderator_name = state.active_debate.moderator.moderator_name
            //todo remember to change api address
            return axios.post('https://apiss.ardkon.com/DonateToModerator?basics=axb', form).then((res) => {

                dispatch('getDonationTotalModerator', { debate_id: form.debate_id, moderator_id: form.moderator_id })
                console.log(res)
            })
        })

    }, async setDonationCandidate({ state, dispatch }, form) {

        multiForage.getItems(['access_token', 'email_address'], function (err, items) {
            form.access_token = items.access_token
            form.email_address = items.email_address
            form.debate_id = state.active_debate.debate_id
            form.candidate_id = state.active_candidate.candidate_id
            form.candidate_first_name = state.active_candidate.candidate_name
            //todo remember to change api address
            return axios.post('https://apiss.ardkon.com/DonateToCandidate?basics=axb', form).then((res) => {
                console.log(res)
                dispatch('getDonationTotalCandidateA', { debate_id: form.debate_id, candidate_id: state.active_debate.candidate_A.candidate_id })
                dispatch('getDonationTotalCandidateB', { debate_id: form.debate_id, candidate_id: state.active_debate.candidate_B.candidate_id })

            })
        })

    }, async setMyChatPros({ commit }, form) {

        multiForage.getItems(['access_token', 'email_address'], function (err, items) {
            form.access_token = items.access_token
            form.email_address = items.email_address
            form.type = 'pros'
            return axios.post('https://apiss.ardkon.com/CreateChatMessage?basics=axb', form)
                .then(response => {
                    commit('SET_MY_CHAT', response.data.data)




                }).catch(error => {
                    console.log(error)
                });
        })

    },async createStatmentVote({ commit }, form) {

        multiForage.getItems(['access_token', 'email_address'], function (err, items) {
            form.access_token = items.access_token
            form.email_address = items.email_address
            return axios.post('https://apiss.ardkon.com/CreateVote?basics=axb', form)
                .then(response => {
                    console.log(response)
                   
                    commit('SET_MY_VOTE', response.data.data.votes_array)




                }).catch(error => {
                    console.log(error)
                });
        })

    }, async setMyChatCons({ commit }, form) {

        multiForage.getItems(['access_token', 'email_address'], function (err, items) {
            form.access_token = items.access_token
            form.email_address = items.email_address
            form.type = 'cons'
            return axios.post('https://apiss.ardkon.com/CreateChatMessage?basics=axb', form)
                .then(response => {
                    commit('SET_MY_CHAT_CONS', response.data.data)




                }).catch(error => {
                    console.log(error)
                });
        })

    }, async addDynamicComment({ commit }, form) {

        commit('ADD_DYNAMIC_CHAT', form)

    }, async setMyQuestion({ commit }, form) {
        multiForage.getItems(['access_token', 'email_address'], function (err, items) {
            form.access_token = items.access_token
            form.email_address = items.email_address
            return axios.post('https://apiss.ardkon.com/CreateQuestion?basics=axb', form)
                .then(response => {
                    commit('SET_MY_QUESTION', response.data.data)




                }).catch(error => {
                    console.log(error)
                });
        })

    }, async getProfile({ commit }, form) {

        console.log("herrwwwe")
        multiForage.getItems(['access_token', 'email_address'], function (err, items) {
            form.access_token = items.access_token
            form.email_address = items.email_address

            console.log(form)
            return axios.post('https://apiss.ardkon.com/GetProfile?basics=axb', form)
                .then(response => {

                    console.log("herrwwwwe")

                    commit('SET_MY_PROFILE', response.data.data.profile_info)




                }).catch(error => {
                    console.log(error)
                });
        })

    }, async getDonationTotalModerator({ commit }, form) {

        return axios.post('https://apiss.ardkon.com/GetDonationTotalModerator?basics=axb', form)
            .then(response => {
                commit('TOTAL_DONATION_MODERATOR', response.data.data.data.total_raised)

                console.log(response)


            }).catch(error => {
                console.log(error)
            });
    }, async getDonationTotalCandidateA({ commit }, form) {

        return axios.post('https://apiss.ardkon.com/GetDonationTotalCandidate?basics=axb', form)
            .then(response => {
                commit('TOTA_DONATION_CANDIDATE_A', response.data.data.data.total_raised)

                console.log(response)


            }).catch(error => {
                console.log(error)
            });
    }, async getDonationTotalCandidateB({ commit }, form) {

        return axios.post('https://apiss.ardkon.com/GetDonationTotalCandidate?basics=axb', form)
            .then(response => {
                commit('TOTA_DONATION_CANDIDATE_B', response.data.data.data.total_raised)

                console.log(response)


            }).catch(error => {
                console.log(error)
            });
    }, async GetChatMessages({ dispatch, commit }, form) {
        form.type = "pros"
      
            dispatch('removeChatMessages').then(r => {
                return axios.post('https://apiss.ardkon.com/GetChatMessages?basics=axb', form)
                .then(response => {
                    commit('SET_CHAT', response.data.data.debate_chat)
    
    
                    console.log(r)
    
                }).catch(error => {
                    console.log(error)
                });
    
            });
    },
    async removeChatMessages({ commit }) {

        commit('REMOVE_CHAT_MESSAGES');

    },async removeChatMessagesCons({ commit }) {

        commit('REMOVE_CHAT_MESSAGES_CONS');

    }, async GetChatMessagesCons({ commit, dispatch }, form) {
        form.type = "cons"
        dispatch('removeChatMessagesCons').then(r => {
            return axios.post('https://apiss.ardkon.com/GetChatMessages?basics=axb', form)
            .then(response => {
                commit('SET_CHAT_CONS', response.data.data.debate_chat)

                console.log(r)



            }).catch(error => {
                console.log(error)
            });

        });
        
    },
    async GetQuestions({ commit }, form) {

        return axios.post('https://apiss.ardkon.com/GetQuestions?basics=axb', form)
            .then(response => {
                commit('SET_QUESTIONS', response.data.data.debate_questions)




            }).catch(error => {
                console.log(error)
            });
    },


    async removeDebateTopics({ commit }) {

        commit('REMOVE_DEBATE_TOPICS');

    },async removeDebateGuests({ commit }) {

        commit('REMOVE_DEBATE_GUESTS');

    },
    async getTopics({ commit, dispatch }, form) {

        return axios.post('https://apiss.ardkon.com/GetTopics?basics=axb', form)
            .then(response => {

                dispatch('removeDebateTopics').then(r => {
                    commit('SET_TOPICS', response.data.data.debate_topics)
                    console.log(r)

                });



            }).catch(error => {
                console.log(error)
            });
    }, async getGuests({ commit, dispatch }, form) {

        return axios.post('https://apiss.ardkon.com/GetGuests?basics=axb', form)
            .then(response => {
                console.log(response)
                dispatch('removeDebateGuests').then(r => {
                    commit('SET_GUESTS', response.data.data.debate_guests)
                    console.log(r)

                });



            }).catch(error => {
                console.log(error)
            });
    }, async getModerator({ commit }, form) {

        return axios.post('https://apiss.ardkon.com/GetModerator?basics=axb', form)
            .then(response => {
                commit('SET_MODERATOR', response.data.data.moderator)




            }).catch(error => {
                console.log(error)
            });
    },
    async removeDebateCandidates({ commit }) {

        commit('REMOVE_DEBATE_CANDIDATES');

    },
    async getCandidates({ commit, dispatch }, form) {

        return axios.post('https://apiss.ardkon.com/GetCandidates?basics=axb', form)
            .then(response => {
                console.log(response)
                dispatch('removeDebateCandidates').then(r => {
                    commit('SET_CANDIDATES', response.data.data.candidates)
                    console.log(r)

                });




            }).catch(error => {
                console.log(error)
            });
    },
    async getMainInfo({ commit }, form) {

        return axios
            .post('https://apiss.ardkon.com/GetInfo?basics=axb', form)
            .then(response => {

                commit('SET_GENERAL_DEBATE', response.data.data.debate_info)



            }).catch(error => {
                console.log(error)
            });
    },

    setDebateActiveDebate({  commit }, debate) {
       
        commit('SET_DEBATE_ACTIVE', debate);

    },     setDebateActive({ state, commit }, param) {
        var debate = state.rooms.find(room => room.debate_param === param)
        console.log(debate)
        commit('SET_DEBATE_ACTIVE', debate);

    }, removeDebateActive({ commit }) {

        commit('REMOVE_DEBATE_ACTIVE');

    },
    async getDebates({ commit }) {

        let form = { debate_status: 'live' }
        return axios
            .post('https://apiss.ardkon.com/GetDebates?basics=axb', form)
            .then(response => {

                console.log(response)
                commit('SET_DEBATES', response.data.data.debates)


            }).catch(error => {
                console.log(error)
            });
    },
    async rmDebates({ commit }) {


        commit('RM_DEBATES')



    },
    setRegion({ commit, dispatch }, form) {

        commit("SET_REGION", form);
        dispatch('createAccount')


    }, setActiveCandidate({ commit }, form) {

        commit("SET_ACTIVE_CANDIDATE", form);

    },
    setType({ commit }, form) {

        commit("SET_TYPE", form);

    }, setInterestRegion({ commit }, form) {

        commit("SET_INTEREST_REGION", form);

    }, setInterest({ commit, dispatch }, form) {

        commit("SET_INTEREST", form);
        dispatch('createAccount')

    },
    createAccount({ state }) {


        return axios
            .post("https://apiss.ardkon.com/NormalSignUp?basics=axb", state.user_registration_information)
            .then((response) => {
                console.log(response)
                console.log("above herw")
                if (response.data.message === "success") {
                    console.log("responseeee")
                    Cookies.set("is_signed_in", true);
                    localforage.setItem("access_token", response.data.data.accessToken);
                    localforage.setItem("email_address", response.data.data.email_address).then(r =>{
                        window.location.reload()
                        console.log(r)
                    });

                } else {
                    console.log(response.data.message)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

}

const debates = {
    namespaced: true,
    state: () => ({
        profile_info: {},
        rooms: [],
        active_candidate: {},
        active_debate: {
            debate_name: '',
            debate_param: '',
            debate_small_description: '',
            debate_id: '',
            debate_user_id: '',
            debate_img_src: '',
            debate_status: '',
            debate_type: '',
            debate_region: '',
            debate_date_time: '',
            candidates: [],
            general: {
                debate_video_url: '',
                debate_status: '',
                debate_date_time: '',
                debate_title: ''
            },
            topics: [],

            guests:[],
            moderator: {},
            chat: [], chat_cons: [],
            my_chat: [], my_chat_cons: [],
            votes_count_up:{},
            votes_count_down:{},
            questions: [],
            my_questions: [],
            donations: {
                candidate_A: 0,
                candidate_B: 0,
                moderator: 0
            }

        },


        user_registration_information: {
            authorization_code: '',
            region: '',
            date_of_birth: '',
            types: [],
            interests: [],
            region_interests: [],


        }

    }),
    mutations: {
        SET_MY_VOTE(state, data){
            for (let i = 0; i <data.length; i++) {

                if(i === 0){
                    state.active_debate.votes_count_down = data[i]

                }    if(i === 1){
                    state.active_debate.votes_count_up = data[i]

                }

            }
            
        },
        REMOVE_CHAT_MESSAGES(state) {
            state.active_debate.chat = []
            
        }, REMOVE_CHAT_MESSAGES_CONS(state) {
            state.active_debate.chat_cons = []
            
        }, REMOVE_DEBATE_GUESTS(state) {
            state.active_debate.guests = []
        },REMOVE_DEBATE_TOPICS(state) {
            state.active_debate.topics = []
        }, REMOVE_DEBATE_CANDIDATES(state) {
            state.active_debate.candidates = []
        },
        REMOVE_QUESTION(state, data) {

            var new_question = []
            for (let i = 0; i < state.active_debate.questions.length; i++) {

                if (state.active_debate.questions[i].question_id != data) {
                    new_question.push(state.active_debate.questions[i])

                }


            }
            state.active_debate.questions = new_question

        }, REMOVE_CHAT(state, data) {

            var new_chat = []
            for (let i = 0; i < state.active_debate.chat.length; i++) {

                if (state.active_debate.chat[i].chat_id != data) {
                    new_chat.push(state.active_debate.chat[i])

                }


            }
            state.active_debate.chat = new_chat

        },
        SET_MY_PROFILE(state, data) {
            state.profile_info = data
        },

        TOTAL_DONATION_MODERATOR(state, data) {
            state.active_debate.donations.moderator = data
        }, TOTA_DONATION_CANDIDATE_A(state, data) {
            state.active_debate.donations.candidate_A = data
        }, TOTA_DONATION_CANDIDATE_B(state, data) {
            state.active_debate.donations.candidate_B = data
        },
        SET_MY_CHAT(state, data) {
            state.active_debate.my_chat.push(data)
        },SET_MY_CHAT_CONS(state, data) {
            state.active_debate.my_chat_cons.push(data)
        }, SET_MY_QUESTION(state, data) {
            state.active_debate.my_questions.push(data)
        },
        SET_MODERATOR(state, data) {
            state.active_debate.moderator = data
        },
        SET_ACTIVE_CANDIDATE(state, data) {
            state.active_candidate = data
        },
        SET_TOPICS(state, data) {

            for (let i = 0; i < data.length; i++) {
                state.active_debate.topics.unshift(data[i]);


            }




        },  SET_GUESTS(state, data) {

            for (let i = 0; i < data.length; i++) {
                state.active_debate.guests.unshift(data[i]);


            }




        },
        SET_CHAT(state, data) {

            for (let i = 0; i < data.length; i++) {
                state.active_debate.chat.push(data[i]);


            }


        }, SET_CHAT_CONS(state, data) {

            for (let i = 0; i < data.length; i++) {
                state.active_debate.chat_cons.push(data[i]);


            }


        }, ADD_DYNAMIC_CHAT(state, data) {


            state.active_debate.chat.push(data);





        }, SET_QUESTIONS(state, data) {

            for (let i = 0; i < data.length; i++) {
                state.active_debate.questions.push(data[i]);


            }


        },
        SET_GENERAL_DEBATE(state, data) {
            state.active_debate.general = data

        },
        SET_CANDIDATES(state, data) {
            for (let i = 0; i < data.length; i++) {
                state.active_debate.candidates.push(data[i]);


            }
        },
        SET_DEBATE_ACTIVE(state, data) {
            state.active_debate.debate_name = data.debate_name
            state.active_debate.debate_param = data.debate_param
            state.active_debate.debate_small_description = data.debate_small_description
            state.active_debate.debate_id = data.debate_id
            state.active_debate.debate_user_id = data.debate_user_id
            state.active_debate.debate_img_src = data.debate_img_src
            state.active_debate.debate_status = data.debate_status
            state.active_debate.debate_type = data.debate_type
            state.active_debate.debate_region = data.debate_region
            state.active_debate.debate_date_time = data.debate_date_time
            state.active_debate.candidate_A = data.candidate_A
            state.active_debate.candidate_B = data.candidate_B


        }, REMOVE_DEBATE_ACTIVE(state) {
            state.active_debate = {}



        },
        RM_DEBATES(state) {
            state.rooms = [];
            state.active_debate.topics = []
            state.active_debate.chat = []
            state.active_debate.questions = []
            state.active_debate.questions = []
        },
        SET_DEBATES(state, data) {

            for (let i = 0; i < data.length; i++) {

                state.rooms.push(data[i]);


            }

        },

        SET_REGION(state, data) {
            state.user_registration_information.region = data.region
            //state.user_registration_information.date_of_birth = data.date_of_birth
            state.user_registration_information.email_address = data.email_address
            state.user_registration_information.first_name = data.first_name
            state.user_registration_information.last_name = data.last_name
        },
        SET_TYPE(state, data) {
            state.user_registration_information.types = data.types

        },
        SET_INTEREST_REGION(state, data) {
            state.user_registration_information.region_interests = data.interests

        },
        SET_INTEREST(state, data) {
            state.user_registration_information.interests = data.interests

        }
    },
    actions,
    getters: {


        getActiveDebate: (state) => (param) => {


            console.log(param)
            return state.rooms.find(room => room.debate_room === param)
        }
    }
}

export default debates
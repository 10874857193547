import Vue from 'vue'
import Vuex from 'vuex'

import ledger_auth from './modules/ledger-auth'
import debates from './modules/debates'
Vue.use(Vuex)


export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    debates,
    ledger_auth,
 
   
     
    
  }
});

<template>
    <div class="wrapper ">
   
        <v-row class="m-0" style="width:100%; z-index:201;position:fixed;" no-gutters>
          <v-col md="4"></v-col>
          <v-col class="pb-0" md="4">
            <v-img contain style="display:block;margin-left:auto;margin-right:auto; cursor: pointer; z-index:202;" src="https://media.discordapp.net/attachments/835173834855284787/835478398260346890/logoled-1.png?width=648&height=463" class="logo-axb"  @click="toHome"></v-img>

          </v-col>
          <v-col class="py-0 my-auto" md="4">

           <v-row>
             <v-col cols="2"></v-col>
             <v-col  cols="2"> <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span  v-bind="attrs"
          v-on="on">
          <v-img class="my-2" id="add" width="24" v-if=" requestLink === false"  @click="add()"  src="https://cdn.discordapp.com/attachments/835173834855284787/835541695315181598/blue_.png"></v-img>
              <v-img class="my-2 profile-img"  id="add"  height="24" width="24" v-if=" requestLink === true"  src="https://cdn.discordapp.com/attachments/835173834855284787/835541687060267028/green_.png"></v-img>
              
                </span> 
      </template>
      <span> request a debate</span>
    </v-tooltip>
              </v-col>
             <v-col cols="2"> 
              <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span  v-bind="attrs"
          v-on="on">
          <v-img class="my-2" id="cand" height="24" width="24"  v-if="applyLink === false" @click="apply()"   src="https://cdn.discordapp.com/attachments/835173834855284787/835540032869629952/applu-1.png"></v-img>
              <v-img class="my-2 profile-img"  id="cand" height="24" width="24" v-if="applyLink === true"  src="https://cdn.discordapp.com/attachments/835173834855284787/835541922557722694/apply_green.png"></v-img>
      
                </span> 
      </template>
      <span> apply</span>
    </v-tooltip>
           
           </v-col>
             <v-col cols="2">
              
              <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span  v-bind="attrs"
          v-on="on">
          <v-img class="my-2" id="rooms" height="24" width="24" v-if="roomsLink === false"  @click="rooms()"  src="https://cdn.discordapp.com/attachments/835173834855284787/835541246674993162/blue_room.png"></v-img>
              <v-img class="my-2 profile-img"  id="rooms"  height="24" width="24" v-if="roomsLink === true"  src="https://cdn.discordapp.com/attachments/835173834855284787/835540772404854824/green_room.png"></v-img>
         
                </span> 
      </template>
      <span> debate rooms</span>
    </v-tooltip>
              </v-col>
             <v-col cols="2">
              

        <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        
        <span
          v-bind="attrs"
          v-on="on"
        >       

                <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span  v-bind="attrs"
          v-on="on">
        <v-img  class="my-2 profile-img"  id="prof"  height="24" width="24" v-if="homeLink === false" @click="home()"  src="https://cdn.discordapp.com/attachments/835173834855284787/835541065585917972/blue_profile.png"></v-img>
                  <v-img class="my-2 profile-img"  id="prof"  height="24" width="24" v-if="homeLink === true"  src="https://cdn.discordapp.com/attachments/835173834855284787/835540758525640725/green_profile.png"></v-img>
                  
                </span> 
      </template>
      <span>profile</span>
    </v-tooltip>
  </span> 
      </template>
      <v-list>
       

                <v-list-item class="m-0" @click="profile">  account
                </v-list-item>
       
      </v-list>
    </v-menu>
             </v-col>
           </v-row>
               
              
              
              
              
              
          </v-col>
        </v-row>
   
       
  
      <div class=" bg-white" id="donate">
  
        <div >
  
          <fade-transition :duration="200" origin="center top" mode="out-in">
            <!-- your content here -->
  
          </fade-transition>
        </div>
  
  
      </div>
  
    </div>
  </template>
  <script>
    /* eslint-disable no-new */

    import store from '@/store'
  
  
  
    import { FadeTransition } from 'vue2-transitions';
  
    export default {
      components: {
        
        FadeTransition,
        
      },
      data() {
            return {
  
                homeLink : false,
                applyLink:false,
                roomsLink:false,
                requestLink:false,
                sharedState: store.state,
  
  
              }
          },
  
     props: {
           logo: {
             type: String,
             default: '/favicon.png',
             description: 'Sidebar app logo'
           },
      },
       computed: {
            cart(){
              return store.state.getAction.active_gallery.length
            },
             joinedHubs(){
                      return store.state.account.joined_hubs
                    },
  
                                  is_signed(){
                                                return this.$cookies.get('is_signed_in')
                                              },
          },
       methods: {
        toHome(){
          this.$router.push({ name: 'debate_main'})
        },
        profile(){
          if(this.is_signed === 'true'){
            this.$router.push({ name: 'account'})

          }else {
            this.$router.push({ name: 'auth'})

          }
        },
        goTo(){
                window.location.href = "https://api.linkedin.com/oauth/v2/authorization?redirect_uri=https%3A%2F%2Fwww.axb.ardkon.com%2Fauth&response_type=code&state=bitchplease&grant_type=authorization_code&scope=r_liteprofile+r_emailaddress+w_member_social&client_id=78dmbk81tskav0"
                console.log('hii')
            },
        home(){
            this.homeLink = true
                this.applyLink = false
                this.roomsLink = false
                this.requestLink = false
        },
        apply(){
            this.homeLink = false
                this.applyLink = true
                this.roomsLink = false
                this.requestLink = false
                this.$router.push({  name: 'BecomeACandidate'})
                
        }, 
        rooms(){
            this.homeLink = false
                this.applyLink = false
                this.roomsLink = true
                this.requestLink = false

                this.$router.push({ name: 'rooms'})
        },
        add(){
            this.homeLink = false
                this.applyLink = false
                this.roomsLink = false
                this.requestLink = true
       
        this.$router.push({  name: 'RequestADebate'})

        },

       
          },
  
    };
  </script>
  <style scoped >
.logo-axb{
  height: 54px
}
 .den-row{
  position:relative;
  height: 54px!important;
  width:100%;
  }
  .bg-secondary-opacity{
    background-color: rgba(226,229,232, 0.7);
  
  }
  .hero-wrapper{
   width: 100vw;
  
   position: fixed;
   align: center;
   justify: left;
   z-index: 100000;
  
  }
    .change_color {
         background-color:rgba(255,255,255, 0.81);
         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     }
     .profile-img:hover{
       
 
 background: url("https://cdn.discordapp.com/attachments/835173834855284787/835540758525640725/green_profile.png") no-repeat;
     }  
      .profile-img{
       
 
 background: url("https://cdn.discordapp.com/attachments/835173834855284787/835540758525640725/green_profile.png") no-repeat;
     } 
     .profile-img-alt:hover{
       
 display: block;

     } .profile-img-alt{
       
 display: none;

     }
  .mobile-donation{
  display: none;
  }
  
  .mobile-account{
  display: inline;
  }
  
  @media only screen and (max-width: 981px) {
    .mobile-donation{
    display: inline;
    }
    .action-menu .navbar-nav .nav-link{
    padding:0!important
  }
  }
  @media only screen and (max-width: 981px) {

    .logo-axb{
  height: 45px
}
}

  
  </style>
  
import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayoutAXB from '@/views/Layout/DashboardLayoutAXB.vue';
import DashboardLayoutAXB1 from '@/views/Layout/DashboardLayoutAXB_1.vue';
import DashboardLayoutAXB_1 from '@/views/Layout/DashboardLayoutAXB_1.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/panel/:panel_name',
    redirect: '/panel/',
    component: DashboardLayoutAXB1,
    children: [

      {
        path: '',
        name: 'panels',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DebateView1.vue')
      },
   

    



    ]
  },

  {
    path: '/',
    redirect: '/',
    component: DashboardLayoutAXB,
    children: [

      {
        path: '/',
        name: 'debate_main',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DebateRooms.vue')
      },
   

    



    ]
  },

  {
    path: '/auth',
    redirect: '/auth',
    component: DashboardLayoutAXB_1,
    children: [

      {
        path: '/',
        name: 'auth',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DebateAuth.vue')
      },
   

    



    ]
  }, 
  
  {
    path: '/DebateSignIn',
    redirect: '/DebateSignIn',
    component: DashboardLayoutAXB_1,
    children: [

      {
        path: '/',
        name: 'DebateSignIn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DebateSignIn.vue')
      },
   

    



    ]
  }, 
  {
    path: '/SignIn',
    redirect: '/SignIn',
    component: DashboardLayoutAXB_1,
    children: [

      {
        path: '/',
        name: 'SignIn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/SignIn.vue')
      },
   

    



    ]
  },
  {
    path: '/',
    redirect: '/',
    component: DashboardLayoutAXB1,
    children: [

      {
        path: '/:debate_param',
        name: 'debate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DebateView.vue')
      },
   

    



    ]
  },
 {
    path: '/axb/moderator',
    redirect: '/axb/moderator',
    component: DashboardLayoutAXB,
    children: [

      {
        path: ':room_id',
        name: 'mederatorRoom',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DebateModeratorRoom.vue')
      },
   

    



    ]
  },

 
  {
    path: '/rooms',
    redirect: '/rooms',
    component: DashboardLayoutAXB,
    children: [

      {
        path: '/rooms',
        name: 'rooms',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DebateRooms.vue')
      },
   

    



    ]
  }, {
    path: '/axb/account',
    redirect: '/axb/account',
    component: DashboardLayoutAXB,
    children: [

      {
        path: '/',
        name: 'account',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DebateAccounts.vue')
      },
   

    



    ]
  }, 
  {
    path: '/RequestADebate',
    redirect: '/RequestADebate',
    component: DashboardLayoutAXB,
    children: [

      {
        path: '/RequestADebate',
        name: 'RequestADebate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DebateRequests.vue')
      },
   

    



    ]
  }, 
  {
    path: '/BecomeACandidate',
    redirect: '/BecomeACandidate',
    component: DashboardLayoutAXB,
    children: [

      {
        path: '/BecomeACandidate',
        name: 'BecomeACandidate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DebateCandidancy.vue')
      },
   

    



    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

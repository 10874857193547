
const actions = {
    closeCandidateDonationModal({  commit }) {
        commit('CLOSE_CANDIDATE_DONATION_MODAL')
    },openCandidateADonationModal({  commit }) {
        commit('OPEN_CANDIDATE_A_DONATION_MODAL')
    },openCandidateBDonationModal({  commit }) {
        commit('OPEN_CANDIDATE_B_DONATION_MODAL')
    }, closeModeratorDonationModal({  commit }) {
        commit('CLOSE_MODERATOR_DONATION_MODAL')
    },openModeratorDonationModal({  commit }) {
        commit('OPEN_MODERATOR_DONATION_MODAL')
    },openModeratorRateModal({  commit }) {
        commit('OPEN_MODERATOR_RATE_MODAL')
    }, setAuthType({  commit }) {
        commit('SET_AUTH_TYPE')
    }, setChat({  commit }) {
        commit('SET_CHAT')
    }, setQuestions({  commit }) {
        commit('SET_QUESTIONS')
    },
    setAuthInterests({  commit }) {
        commit('SET_AUTH_INTERESTS')
    },
     setAuthLocInterests({  commit }) {
        commit('SET_AUTH_LOC_INTERESTS')
    },setModeratorDebate({  commit }) {
        commit('SET_MODERATOR_DEBATE')
    },
    setAuthPannel({commit}) {
        //store.dispatch('debates/createAccount')
        //router.push({ name: 'debate'})
        commit('SET_AUTH_PANNEL')

    },   setAuthSubmit({commit}) {
        //store.dispatch('debates/createAccount')
        //router.push({ name: 'debate'})
        commit('SET_AUTH_SUBMIT')

    },
    setApplyModerator({  commit }) {
        commit('SET_APPLY_MODERATOR')
    }, setApplyCandidate({  commit }) {
        commit('SET_APPLY_CANDIDATE')
    }, showQuestionsSection({  commit }) {
        commit('SHOW_QUESTIONS_SECTION')
    },
}

const ledger_auth = {
    namespaced: true,
    state: () => ({
        auth: {
           
            region: true,
            type: false,
            intrests: false,
            loc_interests: false
        },
        chat_room:{
            chat: false,
            questions: false
        },
        moderator:{
            donation:false,
            rate: false
        }, 
        candidate:{
            donation:false,
            candodate_a_donation:false,
            candodate_b_donation:false
        },
        moderator_debate:{
            sign_in: true,
            debate_room: false 

        },
        apply_auth:{
            type: true, 
            moderator: false, 
            candidate: false
        },
        pannel_sign_up:false
    }),
    mutations: {
        SET_AUTH_PANNEL(state){
            state.pannel_sign_up = !state.pannel_sign_up
        }, SET_AUTH_SUBMIT(state){
            state.pannel_sign_up = false
        },
        SET_APPLY_MODERATOR(state){
            state.apply_auth.type= false;
            state.apply_auth.moderator= true;
        },   SET_APPLY_CANDIDATE(state){
            state.apply_auth.type= false;
            state.apply_auth.candidate= true;
        },
        SET_MODERATOR_DEBATE(state){
            state.moderator_debate.sign_in = false
            state.moderator_debate.debate_room = true
        }, OPEN_MODERATOR_RATE_MODAL(state){
            state.moderator.rate = true
        },
        OPEN_CANDIDATE_A_DONATION_MODAL(state){
            state.candidate.candodate_a_donation = true
        },    OPEN_CANDIDATE_B_DONATION_MODAL(state){
            state.candidate.candodate_b_donation = true
        }, CLOSE_CANDIDATE_DONATION_MODAL(state){
            state.candidate.candodate_a_donation = false
            state.candidate.candodate_b_donation = false
        },  OPEN_MODERATOR_DONATION_MODAL(state){
            state.moderator.donation = true
        }, CLOSE_MODERATOR_DONATION_MODAL(state){
            state.moderator.donation = false
        },
        
        SET_CHAT(state){
            state.chat_room.chat = true
            state.chat_room.questions = false
        }, SET_QUESTIONS(state){
            state.chat_room.chat = false
            state.chat_room.questions = true
        },SHOW_QUESTIONS_SECTION(state){
            state.chat_room.questions = true
        },  SET_AUTH_TYPE(state){
            state.auth.region = false
            state.auth.type = true
        },
         SET_AUTH_INTERESTS(state){
            state.auth.loc_interests = false;
            state.auth.intrests = true;
        },
        SET_AUTH_LOC_INTERESTS(state){
            state.auth.type = false;
            state.auth.loc_interests = true;
        },
    },
    actions
}

export default ledger_auth
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
          light: {
            primary: "#203F5E",
            secondary: "#e2e5e8",
            accent: "#82B1FF",
            error: "#FF5252",
            info: "#132F3E",
            success: "#1A4B35",
            warning: "#FFC107",
            grey: "#808488"
          },
          dark: {
            primary: "#203F5E",
            secondary: "#e2e5e8",
            accent: "#82B1FF",
            error: "#FF5252",
            info: "#132F3E",
            success: "#1A4B35",
            warning: "#FFC107"
          }
        }
      }

});

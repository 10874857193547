var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper "},[_c('v-row',{staticClass:"m-0",staticStyle:{"width":"100%","z-index":"201","position":"fixed"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"4"}}),_c('v-col',{staticClass:"pb-0",attrs:{"md":"4"}},[_c('v-img',{staticClass:"logo-axb",staticStyle:{"display":"block","margin-left":"auto","margin-right":"auto","cursor":"pointer","z-index":"202"},attrs:{"contain":"","src":"https://media.discordapp.net/attachments/835173834855284787/835478398260346890/logoled-1.png?width=648&height=463"},on:{"click":_vm.toHome}})],1),_c('v-col',{staticClass:"py-0 my-auto",attrs:{"md":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[( _vm.requestLink === false)?_c('v-img',{staticClass:"my-2",attrs:{"id":"add","width":"24","src":"https://cdn.discordapp.com/attachments/835173834855284787/835541695315181598/blue_.png"},on:{"click":function($event){return _vm.add()}}}):_vm._e(),( _vm.requestLink === true)?_c('v-img',{staticClass:"my-2 profile-img",attrs:{"id":"add","height":"24","width":"24","src":"https://cdn.discordapp.com/attachments/835173834855284787/835541687060267028/green_.png"}}):_vm._e()],1)]}}])},[_c('span',[_vm._v(" request a debate")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.applyLink === false)?_c('v-img',{staticClass:"my-2",attrs:{"id":"cand","height":"24","width":"24","src":"https://cdn.discordapp.com/attachments/835173834855284787/835540032869629952/applu-1.png"},on:{"click":function($event){return _vm.apply()}}}):_vm._e(),(_vm.applyLink === true)?_c('v-img',{staticClass:"my-2 profile-img",attrs:{"id":"cand","height":"24","width":"24","src":"https://cdn.discordapp.com/attachments/835173834855284787/835541922557722694/apply_green.png"}}):_vm._e()],1)]}}])},[_c('span',[_vm._v(" apply")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.roomsLink === false)?_c('v-img',{staticClass:"my-2",attrs:{"id":"rooms","height":"24","width":"24","src":"https://cdn.discordapp.com/attachments/835173834855284787/835541246674993162/blue_room.png"},on:{"click":function($event){return _vm.rooms()}}}):_vm._e(),(_vm.roomsLink === true)?_c('v-img',{staticClass:"my-2 profile-img",attrs:{"id":"rooms","height":"24","width":"24","src":"https://cdn.discordapp.com/attachments/835173834855284787/835540772404854824/green_room.png"}}):_vm._e()],1)]}}])},[_c('span',[_vm._v(" debate rooms")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.homeLink === false)?_c('v-img',{staticClass:"my-2 profile-img",attrs:{"id":"prof","height":"24","width":"24","src":"https://cdn.discordapp.com/attachments/835173834855284787/835541065585917972/blue_profile.png"},on:{"click":function($event){return _vm.home()}}}):_vm._e(),(_vm.homeLink === true)?_c('v-img',{staticClass:"my-2 profile-img",attrs:{"id":"prof","height":"24","width":"24","src":"https://cdn.discordapp.com/attachments/835173834855284787/835540758525640725/green_profile.png"}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("profile")])])],1)]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"m-0",on:{"click":_vm.profile}},[_vm._v(" account ")])],1)],1)],1)],1)],1)],1),_c('div',{staticClass:" bg-white",attrs:{"id":"donate"}},[_c('div',[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="wrapper ">
 
     
     

    <div class=" bg-white" id="donate">

      <div >

        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->

          <router-view></router-view>
        </fade-transition>
      </div>


    </div>

  </div>
</template>
<script>
  /* eslint-disable no-new */

  import store from '@/store'



  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      
      FadeTransition,
      
    },
    data() {
          return {

              homeLink : false,
              applyLink:false,
              roomsLink:false,
              requestLink:false,
              sharedState: store.state,


            }
        },

   props: {
         logo: {
           type: String,
           default: '/favicon.png',
           description: 'Sidebar app logo'
         },
    },
     computed: {
      pannel_auth(){
        return store.state.ledger_auth.pannel_sign_up
      },
          cart(){
            return store.state.getAction.active_gallery.length
          },
           joinedHubs(){
                    return store.state.account.joined_hubs
                  },

                                is_signed(){
                                              return this.$cookies.get('is_signed_in')
                                            },
        },
     methods: {
      setAuthPannel(){
        store.dispatch('ledger_auth/setAuthPannel')
      },
      toHome(){
        this.$router.push({ name: 'debate_main'})
      },
      profile(){
        this.$router.push({ name: 'account'})
      },
      goTo(){
              window.location.href = "https://api.linkedin.com/oauth/v2/authorization?redirect_uri=https%3A%2F%2Fwww.axb.ardkon.com%2Fauth&response_type=code&state=bitchplease&grant_type=authorization_code&scope=r_liteprofile+r_emailaddress+w_member_social&client_id=78dmbk81tskav0"
              console.log('hii')
          },
      home(){
          this.homeLink = true
              this.applyLink = false
              this.roomsLink = false
              this.requestLink = false
      },
      apply(){
          this.homeLink = false
              this.applyLink = true
              this.roomsLink = false
              this.requestLink = false
              this.$router.push({  name: 'BecomeACandidate'})
              
      }, 
      rooms(){
          this.homeLink = false
              this.applyLink = false
              this.roomsLink = true
              this.requestLink = false

              this.$router.push({ name: 'rooms'})
      },
      add(){
          this.homeLink = false
              this.applyLink = false
              this.roomsLink = false
              this.requestLink = true
     
      this.$router.push({  name: 'RequestADebate'})

      },

     
        },

  };
</script>
<style scoped >
.logo-axb{
height: 54px
}
.den-row{
position:relative;
height: 54px!important;
width:100%;
}
.bg-secondary-opacity{
  background-color: rgba(226,229,232, 0.7);

}
.hero-wrapper{
 width: 100vw;

 position: fixed;
 align: center;
 justify: left;
 z-index: 100000;

}
  .change_color {
       background-color:rgba(255,255,255, 0.81);
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   }
   .profile-img:hover{
     

background: url("https://cdn.discordapp.com/attachments/835173834855284787/835540758525640725/green_profile.png") no-repeat;
   }  
    .profile-img{
     

background: url("https://cdn.discordapp.com/attachments/835173834855284787/835540758525640725/green_profile.png") no-repeat;
   } 
   .profile-img-alt:hover{
     
display: block;

   } .profile-img-alt{
     
display: none;

   }
.mobile-donation{
display: none;
}

.mobile-account{
display: inline;
}

@media only screen and (max-width: 981px) {
  .mobile-donation{
  display: inline;
  }
  .action-menu .navbar-nav .nav-link{
  padding:0!important
}
}
@media only screen and (max-width: 981px) {

  .logo-axb{
height: 45px
}
}


</style>
